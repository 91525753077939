import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  DivisionalMarketingFooter,
  DivisionalFooter,
  CopyrightFooter,
  InformaTechLogo,
} from '../../../../../informakit';
import { HTMLtoJSX } from '../../../../../helpers';

const HubKnect365Footer = (props) => {
  const {
    pageConfig: {
      tenantConfig: { copyright },
    },
  } = props;

  return (
    <DivisionalMarketingFooter>
      <DivisionalFooter
        bottomBorder
        showPrefix
        menuItems={[
          {
            title: 'Home',
            href: 'https://informaconnect.com/',
          },
          {
            title: 'Cookies',
            href: 'https://www.informa.com/generic-content/cookie-policy/',
          },
          {
            title: 'CCPA: Do not sell my personal info',
            href: 'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c1f53e84-9f05-4169-a854-85052b63c50b/5f26b553-52cc-4973-a761-295d5634a6b6.html',
          },
          {
            title: 'Privacy',
            href: 'https://www.informa.com/privacy-policy/',
          },
          {
            title: 'Terms',
            href: 'https://informaconnect.com/terms-and-policies/',
          },
        ]}
        Logo={<InformaTechLogo />}
      />
      <CopyrightFooter>{HTMLtoJSX(copyright)}</CopyrightFooter>
    </DivisionalMarketingFooter>
  );
};

HubKnect365Footer.propTypes = {
  pageConfig: PropTypes.shape({
    tenantConfig: PropTypes.shape({
      copyright: PropTypes.string.isRequired,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(HubKnect365Footer);
